// import Vue from 'vue'

// // Lib imports
// import axios from 'axios'

// Vue.prototype.$http = axios
// s
import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
import router from '../router'

Vue.prototype.$http = axios
// Vue.prototype.$http.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
// Vue.prototype.$http.defaults.baseURL = 'https://backend-laeq.dev-station.com/api/v1/'
Vue.prototype.$http.defaults.baseURL = 'https://centers.muahal.sa/api/v1/'

Vue.prototype.$http.defaults.headers.common.locale = 'ar'
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json;  charset=UTF-8'

Vue.prototype.$http.interceptors.request.use(request => {
  // Update token axios header
  const authToken = request.headers.common.Authorization
  if (!authToken) {
    const loginData = Vue.$cookies.get('super-leaq-login-data', {
      parseJSON: true,
    })
    if (loginData) {
      request.headers.common.Authorization = `Bearer ${loginData.token}`
    }
  }
  return request
})

Vue.prototype.$http.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 400) {
      store.commit(
        'failMessage',
        error.response.data.data.code
          ? error.response.data.data.code
          : 'error',
      )
    }

    if (error.response.status === 401) {
      store.dispatch('logout')
      store.commit('failMessage', error.response.status)
    }
    if (
      error.response.status === 422
            || error.response.status === 403
            || error.response.status === 500
    ) {
      store.commit('failMessage', error.response.status)
    }
    if (error.response.status === 404) {
      router.push({
        name: 'error-404',
      })
    }
  },
)
